export default function() {
	const is_active = 'is-active';
	const is_current = 'is-current';
	const is_open = 'is-open';
	const $masonryWrap = $('#masonryWrap')
	const $masonryContent = $('#masonryContent');
	const $masonryItems = $('.js-masonry-items');
	const $masonryItem = $('.p-masonry-item');
	const $readMore = $('.p-masonry-readMore');
	const $readMoreBtn = $('.js-readMore-trigger');
	const $filterBtn = $('.js-filter').find('button');
	const menuHeight = $('.js-masonry-menu').outerHeight();

	//masonrySelector
	function masonrySelector() {
		$masonryItems.masonry({
			itemSelector: '.p-masonry-item',
			columnWidth: '.p-masonry-gutter',
			gutter: '.p-masonry-sizer',
		});
	}

	// masonry
	$masonryItems.imagesLoaded(function() {
		masonrySelector();
	});

	// フィルターボタン
	$filterBtn.each(function() {
		$(this).on('click', function() {
			const selected = $(this).val();

			$filterBtn.removeClass(is_current);
			$(this).addClass(is_current);

			$('.p-masonry-content').removeClass(function(index, className) {
				return (className.match(/\p-masonry-content--\S+/g) || []).join(' ');
			}).addClass('p-masonry-content--' + selected);

			masonrySelector();
		});
	});

	let masonryItemsFullHeightValue;
	let readMoreInitialHeightValue;
	function setHeight() {
		let masonryItemsHeight = $masonryItems.css('height');
		let masonryItemHeight = $masonryItem.height();
		let readMoreInitialHeight = masonryItemHeight * 6;
		$('html').css( {
			'--masonry-items-fullHeight': masonryItemsHeight,
			'--read-more-initialHeight': readMoreInitialHeight + 'px',
		});
	}

	function updateMasonry() {
		const getMasonryItemsHeight = $('html').css('--masonry-items-fullHeight');
		const getReadMoreInitialHeight = $('html').css('--read-more-initialHeight');
		masonryItemsFullHeightValue = parseFloat(getMasonryItemsHeight);
		readMoreInitialHeightValue = parseFloat(getReadMoreInitialHeight);
	}

	// ウィンドウのリサイズ時にカスタムプロパティを更新
	$(window).on('resize load', function() {
		clearTimeout(window.resizeTimeout); // タイムアウトをクリア
		window.resizeTimeout = setTimeout(function() {
			setHeight();
			updateMasonry();
		}, 500);

		if($masonryItems.height() < readMoreInitialHeightValue) {
			$masonryContent.addClass('is-adjust');
			$readMore.addClass('is-hide')
		}

		$filterBtn.each(function() {
			$(this).on('click', function() {
				if($masonryItems.height() < readMoreInitialHeightValue) {
					$masonryContent.addClass('is-adjust');
					$readMore.addClass('is-hide')
				} else {
					$masonryContent.removeClass('is-adjust');
					$readMore.removeClass('is-hide')
				}

				updateMasonry();
			});
		});
	});

	// ページロード時にも実行
	$(document).ready(function() {
		setHeight();
		updateMasonry();
	});

	$readMoreBtn.on('click', function() {
		$(this).toggleClass(is_active);
		$masonryWrap.toggleClass(is_open);
		$masonryContent.toggleClass(is_open);

		if(!$masonryContent.hasClass(is_open)) {
			$('html,body').animate({
				scrollTop: $masonryWrap.offset().top - 80
			},500);
		}
	});

	// masonry item ホバー処理
	if(window.innerWidth > 768) {
		$masonryItem.hover(
			function() {
				$(this).addClass(is_active);
			},function() {
				$(this).removeClass(is_active);
			}
		);
	}else {
		$masonryItem.on('click', function() {
			$(this).toggleClass(is_active);
		})
	}

}
